<template>
  <div>

    <div class="field">
      <label class="label">Input Address</label>
      <div class="control">
        <input
          v-model="singleLineAddress"
          class="input"
          type="text"
          placeholder="Text input">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <button
        :class="{button: true, 'is-loading': loading}"
        @click="doAnalysis">Get Distances</button>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Zip Code</th>
          <th>Centroid Miles From Address</th>
          <th>Population</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in results" :key="index">
          <td>{{ row.zip }}</td>
          <td>{{ row.distance.toPrecision(2) }}</td>
          <td>{{ row.population }}</td>
        </tr>
      </tbody>
    </table>
    <pre>{{results}}</pre>
  </div>
</template>

<script>
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
// import * as locator from '@arcgis/core/rest/locator';
import Point from '@arcgis/core/geometry/Point';
import FeatureSet from '@arcgis/core/rest/support/FeatureSet';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polyline from '@arcgis/core/geometry/Polyline';
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      apiKey: 'AAPKa23853937e6444c48fdbc4ab66c17f50Na7hTilXs24tTpXDXsNnJHuIp-2owC50IKZxnAFR5wV9Fu6sX2P3JeFmFXZvVhTT',
      geocodeServiceUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
      singleLineAddress: '380 New York St. Redlands CA',
      zipLayer: null,
      zipQueryFeatureSet: null,
      addressResponse: null,
      addressPoint: null,
      results: [],
      loading: true,
    };
  },
  methods: {
    async getAddress() {
      // const url = new URL(this.deckEndpoint);
      const options = {
        method: 'GET',
        params: { f: 'json', singleLine: this.singleLineAddress, token: this.apiKey },
        url: this.geocodeServiceUrl,
      };
      const response = await axios(
        options,
      );
      return response;
    },
    getDistances() {
      for (let i = 0; i < this.zipQueryFeatureSet.features.length; i += 1) {
        const zipPoint = this.zipQueryFeatureSet.features[i].geometry.centroid;
        const path = [[zipPoint.x, zipPoint.y], [this.addressPoint.x, this.addressPoint.y]];
        console.log(zipPoint.toJSON());
        console.log(this.addressPoint.toJSON());
        // const totalDistance = geometryEngine.distance(zipPoint, this.addressPoint, 'miles');
        const distancePolyline = new Polyline(
          { spatialReference: zipPoint.spatialReference, paths: [path] },
        );
        const totalDistance = geometryEngine.geodesicLength(distancePolyline, 'miles');
        this.results.push({
          zip: this.zipQueryFeatureSet.features[i].getAttribute('ZIP_CODE'),
          distance: totalDistance,
          population: this.zipQueryFeatureSet.features[i].getAttribute('POPULATION'),
        });
      }
      this.loading = false;
    },
    doAnalysis() {
      // locator.addressToLocations(
      //   this.geocodeServiceUrl, { SingleLine: this.address, key: this.apiKey },
      // )
      this.results.length = 0;
      this.getAddress().then((response) => {
        this.addressResponse = response.data;
        if (this.addressResponse.candidates.length > 0) {
          const spatialReference = this.addressResponse.spatialReference.wkid;
          const x = this.addressResponse.candidates[0].location.x;
          const y = this.addressResponse.candidates[0].location.y;
          this.addressPoint = new Point({ spatialReference, x, y });
          this.performQuery();
        }
      });
    },
    performQuery() {
      console.log('performing query');
      const query = this.zipLayer.createQuery();
      query.geometry = this.addressPoint; // the point location of the pointer
      query.distance = 50;
      query.units = 'miles';
      query.outFields = ['ZIP_CODE', 'POPULATION'];
      query.spatialRelationship = 'intersects'; // this is the default
      this.zipLayer.queryFeatures(query)
        .then((response) => {
          console.log('got query');
          this.zipQueryFeatureSet = response;
          console.log(response);
          console.log(response.fields);
          console.log(response.toJSON());
          this.getDistances();
        // returns a feature set with features containing the
        // POPULATION attribute and each feature's geometry
        });
    },
  },
  mounted() {
    // const zipLayerUrl = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Boundaries_2020/FeatureServer/0?token=AAPKa23853937e6444c48fdbc4ab66c17f50Na7hTilXs24tTpXDXsNnJHuIp-2owC50IKZxnAFR5wV9Fu6sX2P3JeFmFXZvVhTT';
    const zipLayerUrl = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Boundaries_2020/FeatureServer/3?token=AAPKa23853937e6444c48fdbc4ab66c17f50Na7hTilXs24tTpXDXsNnJHuIp-2owC50IKZxnAFR5wV9Fu6sX2P3JeFmFXZvVhTT';
    this.zipLayer = new FeatureLayer({
      // URL to the service
      apiKey: 'AAPKa23853937e6444c48fdbc4ab66c17f50Na7hTilXs24tTpXDXsNnJHuIp-2owC50IKZxnAFR5wV9Fu6sX2P3JeFmFXZvVhTT',
      url: zipLayerUrl,
    });
    this.doAnalysis();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
